class SideMenu {
  el;
  buttonSubitem;
  subMenu;
  btnClose;
  backdrop;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.backdrop = this.el.querySelector(".site-header__backdrop");
    this.buttonSubitem = this.el.querySelector(".js-menu-item-btn");
    this.subMenu = this.el.querySelector(".menu-item-has-children .sub-menu");
    this.subMenuLinks = this.el.querySelectorAll(".menu-item-has-children .sub-menu a");
    this.btnClose = this.el.querySelector(".js-button-close-submenu");
    //this.main = document.querySelector('#main-content');
    this.init();

    if (this.buttonSubitem) {
      this.backdrop.style.visibility = "hidden";
      this.buttonSubitem.addEventListener("click", this.openSubitems.bind(this));
      this.btnClose.addEventListener("click", this.closeSubitems.bind(this));
      this.backdrop.addEventListener("click", this.closeSubitems.bind(this));
    }
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    const originalTheme = this.el.classList.contains('theme-light') ? 'theme-light' : 'theme-dark';
    const tooltipHeight = this.el.offsetHeight;
    
    if (scrollTop > 0) {
      this.el.classList.add("theme-light", "fixed");
      this.el.classList.remove("theme-dark");
    } else {
      originalTheme == 'theme-light' ? this.el.classList.add("theme-light") : this.el.classList.add("theme-dark");
      this.el.classList.remove("theme-light", "fixed");
      //this.main.style.paddingTop = "0px";
    }
  }

  openSubitems(event) {
    const button = event.currentTarget;
    document.body.classList.add("no-scroll");
    this.buttonSubitem.setAttribute("aria-expanded" , true);
    this.btnClose.classList.add("visible");
    this.backdrop.style.visibility = "visible";
    this.backdrop.classList.add("active");
    this.subMenu.style.display = "block";
    this.subMenu.classList.add("open");
    this.subMenu.classList.remove("close");

    this.subMenuLinks.forEach (link => {
        link.setAttribute("tabindex", 0);
      }
    )
    
  }

  closeSubitems() {
    console.log('click');
    this.buttonSubitem.setAttribute("aria-expanded" , false);
    this.subMenu.style.transition = "transform 1s cubic-bezier(0.86, 0, 0.07, 1)";
    this.subMenu.classList.add("close");
    this.subMenuLinks.forEach (link => {
      link.setAttribute("tabindex", -1);
    })


    this.subMenu.classList.remove("open");
    setTimeout(() => {
      this.btnClose.classList.remove("visible");
      this.backdrop.classList.remove("active");
    }, 500);

    setTimeout(() => {
      document.body.classList.remove("no-scroll");
    }, 1200);
  }
  init() {
    const subMenuLinks = this.el.querySelectorAll(".menu-item-has-children .sub-menu a");

    subMenuLinks.forEach(link => {
        link.setAttribute('tabindex', '-1');
    });
  }
}

const sideMenu = document.querySelector(".js-site-header");
if (sideMenu) {
  new SideMenu(sideMenu);
}


class LanguageDropdown {
  constructor(selector) {
      this.dropdown = document.querySelector(selector);
      if(this.dropdown) {
        this.button = this.dropdown.querySelector('.language-selector__button'); 
        this.menu = this.dropdown.querySelector('.language-selector__menu');
      }

      this.init();
  }

  init() {
      // Agregar eventos
      this.button ? this.button.addEventListener('click', (event) => this.toggleMenu(event)) : null;
      document.addEventListener('click', (event) => this.handleDocumentClick(event));
  }

  toggleMenu(event) {
      event.stopPropagation(); // Para evitar el cierre inmediato al hacer clic en el botón
      const isExpanded = this.button.getAttribute("aria-expanded") === "true";

      this.button.setAttribute("aria-expanded", !isExpanded);
      this.menu.classList.toggle('show');
  }

  handleDocumentClick(event) {
      if (this.dropdown && !this.dropdown.contains(event.target)) {
          this.menu.classList.remove('show');
      }
  }
}

// Inicializar el menú desplegable
document.addEventListener('DOMContentLoaded', () => {
  new LanguageDropdown('.site-header .language-selector');
  new LanguageDropdown('.side-menu .language-selector');


  const pageHeader = document.querySelector('.page-header');
  if(pageHeader) {
    const deviceHeight = window.innerHeight;
    pageHeader.style.height = deviceHeight + "px";
  }
});